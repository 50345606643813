import React from "react";

// MATERIAL UI
import { Button } from "@mui/material";

// COMPONENTS
import Layout from "components/Layout";
import ThemeCard, { ThemeSection } from "components/ThemeCard";
import CallToAction from "components/CallToAction";

// IMAGES
import ThemeBento from "assets/themes/Bento.png";
import ThemePratama from "assets/themes/Pratama.png";
import ThemePratamaDark from "assets/themes/Pratama Dark.png";
import ThemeLawson from "assets/themes/Lawson.png";
import ThemeCuna from "assets/themes/Cuna.png";
import ThemeNova from "assets/themes/Nova.png";

const ThemesPAge = () => {
  return (
    <Layout pageTitle="Themes" metaTitle="Customize your bookmark manager">
      <ThemeSection>
        <ThemeCard title="Bento" image={ThemeBento} />
        <ThemeCard title="Patrama" image={ThemePratama} />
        <ThemeCard title="Patrama Dark" image={ThemePratamaDark} />
        <ThemeCard title="Lawson" image={ThemeLawson} />
        <ThemeCard title="Cuna" image={ThemeCuna} />
        <ThemeCard title="Nova" image={ThemeNova} />
      </ThemeSection>

      <CallToAction
        title="Don't get lost between lots of links"
        description="Stay organized and focused on what realle matters, your content, not where you save it"
        cta={
          <Button
            variant="contained"
            href="https://chrome.google.com/webstore/detail/pau/oiblkeiiodficdoblmjihonngeejcmbo"
            target="_blank"
            rel="noreferrer"
            data-splitbee-event="Redirect: Chrome Store"
          >
            Add Pau To My Browser 🚀
          </Button>
        }
      />
    </Layout>
  );
};

export default ThemesPAge;
